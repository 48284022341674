<template>
  <div class="login_contain center">
    <div class="br-50 bg-w hidden align-center login_box">
      <div class="wb-50 hb-100">
        <img :src="imageUrl + 'live-002.png'" alt="" class="wb-100 hb-100" />
      </div>
      <div class="wb-50 hb-100 column align-center pt-55 pb-55 pl-58 pr-76">
        <div class="wb-100 text-center c-blue fz-40 mb-66">
          欢迎登录赛事直播平台
        </div>
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          :rule="rule"
          class="fz-30 wb-100"
        >
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="form.username"
              placeholder="用户名"
              class="br-20"
            />
          </el-form-item>
          <el-form-item label="密码" prop="password" class="mt-63">
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              class="br-20"
              show-password
            />
          </el-form-item>
          <el-form-item class="mt-25">
            <div class="align-center pointer" @click="handleCheck(false)">
              <div
                class="w-42 h-42 center fz-30 br-10 mr-31"
                :class="isCheck ? 'is_check_bg' : 'is_cherk_border'"
              >
                <i class="el-icon-check t-w"></i>
              </div>
              <div class="c-6 fz-30">记住密码</div>
            </div>
          </el-form-item>
          <el-form-item class="mt-65">
            <div
              class="login_btn wb-100 h-90 center fz-36 t-w pointer br-50"
              @click="handleLogin"
            >
              登录
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "@/common/common";
import { login } from "@/common/api/login";
export default {
  data() {
    return {
      imageUrl,
      isCheck: false,
      form: {
        username: "",
        password: "",
      },
      rule: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    const loginForm = window.localStorage.getItem("loginForm");
    if (loginForm) {
      this.form = JSON.parse(loginForm);
      this.isCheck = this.form.isCheck || false;
    }
  },
  methods: {
    // 登录
    async handleLogin() {
      const {
        form: { username, password },
      } = this;
      const params = {
        row: {
          username,
          password,
        },
      };
      const { data, code, msg } = await login(params);
      if(code == 1) {

        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("schoolInfo", JSON.stringify(data.admin));
        this.handleCheck(true);
        this.$router.push("/");
      } else {
        // this.$message.error(msg)
      }
    },
    handleCheck(islogin = false) {
      if (!islogin) {
        this.isCheck = !this.isCheck;
      }
      if (this.isCheck) {
        this.form.isCheck = this.isCheck;
        const loginFormStr = JSON.stringify(this.form);
        window.localStorage.setItem("loginForm", loginFormStr);
      } else {
        window.localStorage.removeItem("loginForm");
      }
    },
  },
};
</script>
<style lang="scss">
.login_contain {
  width: 100vw;
  height: 100%;
  background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/image/live-001.png")
    no-repeat;
  background-size: cover;
  .login_box {
    width: 1576px;
    height: 838px;
  }
}

.el-form-item__label {
  font-size: 30px !important;
}

.el-input__inner {
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  border-radius: 20px;
  margin-top: 17px;
  padding: 38px 20px;
}

.is_cherk_border {
  background-color: #fff;
  border: 1px solid #dcdfe6;
}

.is_check_bg {
  background-color: #4aa6ff;
  color: #fff;
}

.login_btn {
  background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
  box-shadow: 0px 4px 12px 1px rgba(78, 136, 240, 0.36);
}

.el-input__icon {
  position: relative;
  &::before {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 58%;
    right: 10px;
    transform: translateY(-50%);
  }
}
</style>
